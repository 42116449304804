import type { GoogleMapProps } from '../../GoogleMap.types';

type Locations = GoogleMapProps['mapData']['locations'];
type LocationProps = keyof Locations[0];

export const shouldKeepMarkers = (
  locations: Locations,
  prevLocations: Locations,
): boolean => {
  if (locations.length !== prevLocations.length) {
    return false;
  }
  return locations.every((location, idx) => {
    const locationKeys = Object.keys(location) as Array<LocationProps>;
    return (
      locationKeys.length === Object.keys(prevLocations[idx]).length &&
      locationKeys.every(p => location[p] === prevLocations[idx][p])
    );
  });
};

// data-fixer for old URLs created without the isSEOBot option
const fixOldPinUrl = (url: string) => {
  if (url && url.endsWith('.webp')) {
    try {
      const originalExtension = url.match(/media\/[^/]+/)![0].split('.')[1];
      url = url.replace(/.webp$/, `.${originalExtension}`);
    } catch (e) {}
  }
  return url;
};

type PinColor = Locations[0]['pinColor'];
type PinIcon = Locations[0]['pinIcon'];

const getIcon = (pinIcon: PinIcon, pinColor: PinColor) => {
  if (!pinIcon && !pinColor) {
    return undefined;
  }

  if (pinColor) {
    return {
      path: pinIcon,
      fillColor: pinColor,
      strokeColor: pinColor,
      fillOpacity: 1,
      scale: 0.5,
    };
  }

  return fixOldPinUrl(pinIcon);
};

export const fillLocationsWithIcon = (mapData: GoogleMapProps['mapData']) => {
  return {
    ...mapData,
    locations: mapData.locations.map(location => ({
      ...location,
      icon: getIcon(location.pinIcon, location.pinColor),
    })),
  };
};
